/* eslint-disable jsx-a11y/control-has-associated-label */
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';

import HeartIcon from 'Component/HeartIconAnimation/HeartIconAnimation.component';
import {
    ProductWishlistButtonComponent as SourceProductWishlistButtonComponent,
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButtonAnimation.pdp.style';
import './ProductWishlistButtonAnimation.plp.style';

/** @namespace Inov8/Component/ProductWishlistButtonAnimation/Component */
export class ProductWishlistButtonAnimationComponent
    extends SourceProductWishlistButtonComponent {
    // TODO implement logic

    renderButton(): ReactElement {
        const { isInWishlist, isDisabled, mix } = this.props;

        return (
            <button
              block="ProductWishlistButtonAnimation"
              elem="Button"
              mods={ { isInWishlist, isDisabled } }
              mix={ { block: 'Button', mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                <HeartIcon isActive={ isInWishlist } />
            </button>
        );
    }
}

export default ProductWishlistButtonAnimationComponent;
