/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-typos */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ReviewHalfStar from 'Component/ReviewHalfStar/ReviewHalfStar.component';
import Star from 'Component/Star/Star.component';

import './TrustPilotReviews.style';

/** @namespace Inov8/Component/TrustPilotReviews/Component */
export class TrustPilotReviewsComponent extends PureComponent {
    static propTypes = {
        product: PropTypes.object,
        noClick: PropTypes.bool,
        productSchema: PropTypes.bool,
    };

    static defaultProps = {
        hideCount: false,
    };

    stars(fullStars, halfStar) {
        const starComponents = Array.from({ length: fullStars }, (_, i) => (
            <Star key={ `star-${i}` } />
        ));

        if (halfStar) {
            starComponents.push(<ReviewHalfStar key="half-star" />);
        }

        return starComponents;
    }

    calculateTrustpilotStars(averageRating) {
        let fullStars;
        let halfStar;

        if (averageRating >= 1.0 && averageRating <= 1.2) {
            fullStars = 1;
            halfStar = false;
        } else if (averageRating >= 1.3 && averageRating <= 1.7) {
            fullStars = 1;
            halfStar = true;
        } else if (averageRating >= 1.8 && averageRating <= 2.2) {
            fullStars = 2;
            halfStar = false;
        } else if (averageRating >= 2.3 && averageRating <= 2.7) {
            fullStars = 2;
            halfStar = true;
        } else if (averageRating >= 2.8 && averageRating <= 3.2) {
            fullStars = 3;
            halfStar = false;
        } else if (averageRating >= 3.3 && averageRating <= 3.7) {
            fullStars = 3;
            halfStar = true;
        } else if (averageRating >= 3.8 && averageRating <= 4.2) {
            fullStars = 4;
            halfStar = false;
        } else if (averageRating >= 4.3 && averageRating <= 4.7) {
            fullStars = 4;
            halfStar = true;
        } else if (averageRating >= 4.8 && averageRating <= 5.0) {
            fullStars = 5;
            halfStar = false;
        } else {
            // Handle cases where the rating is outside the expected range
            fullStars = 0;
            halfStar = 0;
        }

        return {
            fullStars,
            halfStar,
            emptyStars: 5 - fullStars - (halfStar ? 1 : 0),
        };
    }

    render() {
        const {
            product, hideCount, customerReview, noClick, productSchema,
        } = this.props;

        if (!product?.getTrustpilotProductRating) {
            return null;
        }

        let { starsAverage, numberOfReviews } = product.getTrustpilotProductRating;

        if (customerReview) {
            starsAverage = customerReview;
        }

        const total = numberOfReviews ? numberOfReviews.total : 0;
        const { fullStars, halfStar } = this.calculateTrustpilotStars(starsAverage);

        // Handle click event
        const handleClick = () => {
            const element = document.getElementById('TrustpilotProductReviews');

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        };

        return (
            <div
              block="TrustPilotReviews"
              itemType={ productSchema ? 'http://schema.org/AggregateRating' : undefined }
              itemProp={ productSchema ? 'aggregateRating' : undefined }
              itemScope={ productSchema || undefined }
              onClick={ noClick ? handleClick : undefined }
            >
                { productSchema && (
                    <>
                        <meta itemProp="ratingValue" content={ String(starsAverage) } />
                        <meta itemProp="reviewCount" content={ String(total) } />
                    </>
                ) }
                { this.stars(fullStars, halfStar) }
                { !hideCount && (
                    <span block="TotalCount">
                        { total }
                        { ' ' }
                        reviews
                    </span>
                ) }
            </div>
        );
    }
}

export default TrustPilotReviewsComponent;
