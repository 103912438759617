/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';

import {
    CompareIconComponent as SourceCompareIconComponent,
} from 'SourceComponent/CompareIcon/CompareIcon.component';

import './CompareIconAnimation.style';

/** @namespace Inov8/Component/CompareIconAnimation/Component */
export class CompareIconAnimationComponent extends SourceCompareIconComponent {
    // TODO implement logic

    render(): ReactElement {
        const {
            isActive,
        } = this.props;

        return (
            <div block="CompareIcon">
                <div block="compare-btn-text">{ isActive ? 'Comparing' : 'Compare' }</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="11.48" height="13.66">
                    <g transform="translate(.5 .7)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="m6.57 3.43-1.71-1.7L6.57 0" />
                        <path d="m3.6 8.83 1.7 1.71-1.7 1.72" />
                        <path d="M5.56 1.7h1.29a2.58 2.58 0 0 1 2.58 2.58v5.86" />
                        <path d="M4.9 10.51H3.62a2.36 2.36 0 0 1-2.57-2.04V2.13" />
                        <circle cx="1.03" cy="1.03" r="1.03" />
                        <circle cx="9.45" cy="11.23" r="1.03" />
                    </g>
                </svg>
            </div>
        );
    }
}

export default CompareIconAnimationComponent;
