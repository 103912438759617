/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

// import { PureComponent } from 'react';
// import TrustPilotQuery from 'src/query/TrustPilotReviews.query';
import DataContainer from 'SourceUtil/Request/DataContainer';

import TrustPilotReviews from './TrustPilotReviews.component';

/** @namespace Inov8/Component/TrustPilotReviews/Container */
export class TrustPilotReviewsContainer extends DataContainer {
    static propTypes = {
        // TODO: implement prop-types
        product: PropTypes.object,
        noClick: PropTypes.bool,
        productSchema: PropTypes.bool,
    };

    __construct(props) {
        super.__construct(props);
    }

    render() {
        const {
            product, hideCount, customerReview, noClick, productSchema,
        } = this.props;

        return (
            <TrustPilotReviews
              product={ product }
              customerReview={ customerReview }
              hideCount={ hideCount }
              noClick={ noClick }
              productSchema={ productSchema }
            />
        );
    }
}

export default TrustPilotReviewsContainer;
