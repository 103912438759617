import {
    HeartIconComponent as SourceHeartIconComponent,
} from 'SourceComponent/HeartIcon/HeartIcon.component';

import './HeartIcon.style';

/** @namespace Inov8/Component/HeartIcon/Component */
export class HeartIconComponent extends SourceHeartIconComponent {
    // TODO implement logic
}

export default HeartIconComponent;
