/* eslint-disable no-unneeded-ternary */
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';

import AniTextIconBtn from 'Component/AniTextIconBtn/AniTextIconBtn.component';
import CompareIcon from 'Component/CompareIcon/CompareIcon.component';
import {
    ProductCompareButtonComponent as SourceProductCompareButtonComponent,
} from 'SourceComponent/ProductCompareButton/ProductCompareButton.component';

import './ProductCompareButton.style';

/** @namespace Inov8/Component/ProductCompareButton/Component */
export class ProductCompareButtonComponent extends SourceProductCompareButtonComponent {
    render(): ReactElement {
        const { isActive, blockColor } = this.props;

        const chooseBlockColor = blockColor ? blockColor : 'grape-leaf-btn';

        return (
            <AniTextIconBtn
              text={ isActive ? 'COMPARING' : 'COMPARE' }
              block={ !isActive ? chooseBlockColor : `${chooseBlockColor}_isActive` }
              onClick={ this.handleClick }
              icon={ <CompareIcon /> }
            />

        );
    }
}

export default ProductCompareButtonComponent;
