import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import { PureComponent } from 'react';

import { TickIconComponentProps } from './TickIcon.type';

import './TickIcon.style';

/** @namespace Inov8/Component/TickIcon/Component */
export class TickIconComponent extends PureComponent<TickIconComponentProps> {
    render(): ReactElement {
        return (
            <div block="TickIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
                    <g
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                    <path
                      data-name="Path 39833"
                      d="m6.8 9 3.2 3L20.5 1.7"
                    />
                    <path
                      data-name="Path 39834"
                      d="M19.4 10v7.3a2.1 2.1 0 0 1-2 2.1H2.5a2.1 2.1 0 0 1-2.1-2V2.5A2.1 2.1 0 0 1 2.6.5h11.6"
                    />
                    </g>
                </svg>
            </div>
        );
    }
}

export default TickIconComponent;
