/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import { CompareIconComponentProps } from 'SourceComponent/CompareIcon/CompareIcon.type';
import { ReactElement } from 'Type/Common.type';

import './CompareIcon.style';

/** @namespace Inov8/Component/CompareIcon/Component */
export class CompareIconComponent extends PureComponent<CompareIconComponentProps> {
    static defaultProps: Partial<CompareIconComponentProps> = {
        isActive: false,
    };

    render(): ReactElement {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="11.48" height="13.66">
                <g transform="translate(.5 .7)" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M6.57 3.43 4.86 1.73 6.57 0" />
                    <path d="M3.6 8.83l1.7 1.71-1.7 1.72" />
                    <path d="M5.56 1.7h1.29a2.58 2.58 0 0 1 2.58 2.58v5.86" />
                    <path d="M4.9 10.51H3.62a2.36 2.36 0 0 1-2.57-2.04V2.13" />
                    <circle cx="1.03" cy="1.03" r="1.03" />
                    <circle cx="9.45" cy="11.23" r="1.03" />
                </g>
            </svg>

        );
    }
}

export default CompareIconComponent;
