import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';

import {
    HeartIconComponent as SourceHeartIconComponent,
} from 'SourceComponent/HeartIcon/HeartIcon.component';

import './HeartIconAnimation.style';

/** @namespace Inov8/Component/HeartIconAnimation/Component */
export class HeartIconAnimationComponent extends SourceHeartIconComponent {
    // TODO implement logic
    render(): ReactElement {
        return (
            <div block="HeartIconAnimation">
                <div block="heart-btn-text">Favourite</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="14.42" height="12.95">
                    <path
                      data-name="Icon feather-heart"
                      d="M12.88 1.78a3.53 3.53 0 0 0-4.99 0l-.68.68-.68-.68a3.53 3.53
                    0 1 0-5 5l.69.67 4.99 5 5-5 .67-.68a3.53 3.53 0 0 0 0-5Z"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                </svg>
            </div>
        );
    }
}

export default HeartIconAnimationComponent;
