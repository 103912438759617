import { PureComponent } from 'react';

import { SelectedOptionLabelComponentProps } from './SelectedOptionLabel.type';

import './SelectedOptionLabel.style';
/** @namespace Inov8/Component/SelectedOptionLabel/Component */
export class SelectedOptionLabelComponent extends PureComponent<SelectedOptionLabelComponentProps> {
    render() {
        const { label } = this.props;

        return (
            <div block="SelectedOptionsLabel" elem="SelectedLabel">
           <strong> Colours:</strong>
            { ' ' }
            { label }
            </div>
        );
    }
}

export default SelectedOptionLabelComponent;
