/* eslint-disable react/forbid-dom-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import { PureComponent } from 'react';

import { AniTextIconBtnComponentProps } from './AniTextIconBtn.type';

import './AniTextIconBtn.style';

/** @namespace Inov8/Component/AniTextIconBtn/Component */
export class AniTextIconBtnComponent extends PureComponent<AniTextIconBtnComponentProps> {
    render() {
        const {
            block, text, icon, onClick, disabled,
        } = this.props;

        const blockName = `ani-text-icon-btn ${block} ${disabled ? 'disabled' : ''}`; // Add 'disabled' class if disabled

        return (
            <div block="AniTextIconBtn" onClick={ disabled ? undefined : onClick } className={ disabled ? 'disabled' : '' }>
                <div block={ blockName }>
                    <div block="text">{ text }</div>
                    <div block="arrow">
                        { icon }
                    </div>
                </div>
            </div>
        );
    }
}

export default AniTextIconBtnComponent;
