import { PureComponent } from 'react';

import { ReviewHalfStarComponentProps } from './ReviewHalfStar.type';

import './ReviewHalfStar.style';

/** @namespace Inov8/Component/ReviewHalfStar/Component */
export class ReviewHalfStarComponent extends PureComponent<ReviewHalfStarComponentProps> {
    render() {
        return (
            <svg block="ReviewHalfStar" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                    <path d="M288 0c-12.2 .1-23.3 7-28.6 18L195 150.3 51.4 171.5c-12 1.8-22
                    10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23
                    8 33.8 2.3L288 439.8V0zM429.9 512c1.1 .1 2.1 .1 3.2 0h-3.2z"
                    />
            </svg>
        );
    }
}

export default ReviewHalfStarComponent;
