import { PureComponent } from 'react';

import { RightArrowComponentProps } from './RightArrow.type';

import './RightArrow.style';

/** @namespace Inov8/Component/RightArrow/Component */
export class RightArrowComponent extends PureComponent<RightArrowComponentProps> {
    render() {
        return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
                <line x1="5" y1="12" x2="19" y2="12" />
                <polyline points="12 5 19 12 12 19" />
            </svg>
        );
    }
}

export default RightArrowComponent;
