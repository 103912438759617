import { PureComponent } from 'react';

import { QuickAddIconComponentProps } from './QuickAddIcon.type';

import './QuickAddIcon.style';

/** @namespace Inov8/Component/QuickAddIcon/Component */
export class QuickAddIconComponent extends PureComponent<QuickAddIconComponentProps> {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="22.2" height="22.2">
                <g
                  data-name="Icon feather-plus-circle"
                  fill="none"
                  stroke="#485046"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                <path data-name="Path 39887" d="M21.7 11.1A10.6 10.6 0 1 1 11.1.5a10.6 10.6 0 0 1 10.6 10.6Z" />
                <path data-name="Path 39888" d="M11.1 6.9v8.4" />
                <path data-name="Path 39889" d="M6.9 11.1h8.4" />
                </g>
            </svg>
        );
    }
}

export default QuickAddIconComponent;
