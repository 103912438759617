// import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ProductCompareButtonComponent as ProductCompareButton }
    from 'Component/ProductCompareButton/ProductCompareButton.component';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCompareButtonContainer as SourceProductCompareButtonContainer,
} from 'SourceComponent/ProductCompareButton/ProductCompareButton.container';
import { ProductCompareButtonComponentContainerPropKeys, ProductCompareButtonComponentProps }
    from 'SourceComponent/ProductCompareButton/ProductCompareButton.type';
import { RootState } from 'Util/Store/Store.type';
/** @namespace Inov8/Component/ProductCompareButton/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Inov8/Component/ProductCompareButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Inov8/Component/ProductCompareButton/Container */
export class ProductCompareButtonContainer extends SourceProductCompareButtonContainer {
    containerProps(): Pick<ProductCompareButtonComponentProps, ProductCompareButtonComponentContainerPropKeys> {
        const { mix } = this.props;
        const { isLoading } = this.state;

        return {
            mix,
            isLoading,
            isActive: this.isActive(),
        };
    }

    render(): ReactElement {
        const {blockColor} = this.props;

        return (
            <ProductCompareButton
              blockColor={ blockColor }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCompareButtonContainer);
